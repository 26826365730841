<template>
    <div>
        <div>
            <div class="az-content-breadcrumb">
                <span><router-link :to="{ name: 'admin-home'}">Admin</router-link></span>
                <span>Dashboard</span>
                <div class="mg-l-auto">
                    <router-link :to="{ name: 'dashboard'}">
                        <i class="fa fa-arrow-left"/>
                        Back
                    </router-link>
                </div>
            </div>
            <h2 class="az-content-title mg-b-0">Admin</h2>
            <p class="mg-b-10" v-html="$DEFINITIONS.admin.about"></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AdminHome"
    }
</script>

<style scoped>
</style>